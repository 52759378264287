@import "../../css/variables";

.page-loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
    background: $white;
}

.async-loader-wrapper {
    opacity: 0.8;
    display: none;
    @extend .page-loader-wrapper;
}

.page-loader-wrapper, .async-loader-wrapper{
    .loader {
        display: block;
        position: relative;
        left: 50%;
        top: 50%;
        width: 150px;
        height: 150px;
        margin: -75px 0 0 -75px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: #05dbf7;
        text-align: center;
        animation: spin 2s linear infinite;

        &:before {
            content: "";
            position: absolute;
            top: 5px;
            left: 5px;
            right: 5px;
            bottom: 5px;
            border-radius: 50%;
            border: 3px solid transparent;
            border-top-color: #e548a5;
            animation: spin 3s linear infinite;
        }

        &:after {
            content: "";
            position: absolute;
            top: 15px;
            left: 15px;
            right: 15px;
            bottom: 15px;
            border-radius: 50%;
            border: 3px solid transparent;
            border-top-color: #afff00;
            animation: spin 1.5s linear infinite;
        }
    }

    @keyframes spin {
        0%   {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}