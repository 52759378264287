// **********
// Color
$jakalaprimary: #cc7c1b;
$jakalasecondary: #ffc37a;
$jakalatertiary: #995d14;

$white: #ffffff;
$black: #000000;
$color-selection: $white;

$gray-100: #f6f6f6;
$gray-200: #ededed;
$gray-300: #d6d6d6;
$gray-400: #b3b3b3;
$gray-500: #b9b9b9;
$gray-600: #979797;
$gray-700: #3c3c3c;
$gray-800: #181818;

$color-preloader1: $jakalaprimary;
$color-preloader2: $jakalasecondary;
$color-preloader3: $jakalatertiary;

$red-color: #dc3545;;

$color-primary: #333;

$color-primary-light: #cc7c1b;
$color-primary-light-lighter: #ffc37a;
$color-primary-dark: $gray-700;

$subnavmenu: #072c170a;


$color-tints: #2a2d33;
$text-color: #202020;
$color-darker: #1D1F2D;
$color-dark: #4A4A4A;
$color-dark-light: #919191;
$color-dark-lighter: #C7C7C7;
$color-dark-disabled: #e1e1e1;

$color-danger: #DE4040;;
$color-danger-light: #f17d7d;
$color-danger-dark: #a41414;

$color-warning: #d7ab3b;
$color-warning-light: #f6d172;
$color-warning-dark: #ae830e;

$color-success: #2abd79;
$color-success-light: #52eea6;
$color-success-dark: #17985c;

$white-with-transparency: rgba(255, 255, 255, 0.05);
$white: #ffffff;
$black: #000000;
$yellow: #FFD547;
$blue: #052850;
$light-blue: #00AFB9;
$color-selection: $white;


// **********
// Define imgPath example: url("#{$imgPath}/logo_icon.png")
$imgPath: "../images";


// **********
// Font family
// fontPath
$fontPath: "../fonts/";

// fontFace
$SFProDisplayRegular: 'SFProDisplayRegular';

// Google Fonts
$opensans: 'Open Sans', sans-serif;

$font-main: 'Lato';
$font-headings: 'Roboto';


// **********
// Layout
$chosen-breakpoint-sm: 'sm';
$chosen-breakpoint-md: 'md';
$chosen-breakpoint-sm: 'lg';
$chosen-breakpoint-xl: 'xl';
$chosen-breakpoint-xxl: 'xxl';

$menu-z-index-mobile: 15;

// **********
// Header



// **********
// Navbar
$navbar-height-mobile: 40px;
$navbar-height-desktop: 50px;

// **********
// Footer
$footer-height-mobile: 40px;
$footer-height-desktop: 50px;


